import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row team-row">
          <div className="col-md-8">
            <h4>Your Privacy - Our Commitment</h4>
            <p>1.General</p>
            <p>
              <strong>1.1</strong> <strong>BOHORE AGGREGATOR PVT LTD,</strong> a
              company incorporated under the laws of India, having its
              registered address at 50 SANJAY COLONY, WARD NO -07, DANDAPURA,
              Vidisha, Vidisha, Vidisha- 464001, Madhya Pradesh (“we”, “us” or
              “our”) manages and operates a website located at the URL{" "}
              <a href="https://www.avijo.in">www.avijo.in</a> and a mobile
              application under the brand name avijo (collectively referred to
              as the “Website”) where you can place orders to purchase the
              Products and/or Services offered by us to the users of the Website
              (“User” or “Users” or “you” or “your”).
            </p>

            <p>
              <strong>1.2</strong> This privacy policy (“Privacy Policy”),
              together with the{" "}
              <Link to="/policy/Terms-and-Conditions">Terms and Conditions</Link>{" "}
              governs your use of the “Website” and describes our policies and
              procedures on the collection, use, disclosure, processing,
              transfer, and storage of the information provided to us by you. By
              using, browsing, accessing, or purchasing from the “Website” you
              agree to be bound by the terms of this Privacy Policy and consent
              to the collection, storage, possession, dealing, handling,
              sharing, disclosure or transfer of your information in accordance
              with the terms of the Privacy Policy. We shall not use the User’s
              information in any manner except as provided under this Privacy
              Policy. Capitalized terms used herein if not defined shall have
              the same meaning as ascribed to them under the Terms.
            </p>

            <p>
              <strong>1.3</strong> This document is an electronic record and is
              governed by the provisions under the Information Technology Act,
              2000 and rules made thereunder as may be applicable, and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </p>

            <p>
              <strong>1.4</strong> This Privacy Policy sets out the type of
              information collected from the Users, including the nature of the
              Sensitive Personal Data or Information (defined hereinafter), the
              purpose, means and modes of usage of such information and how and
              to whom such information shall be transferred or disclosed by us.
              You may, at any time withdraw your consent for collection and use
              of your information including Personal Information (defined
              hereinafter) or Sensitive Personal Data or Information. However,
              please note that if you withdraw your consent, we may no longer be
              able to provide you with the corresponding service for which you
              have withdrawn your consent. It is hereby clarified that your
              decision to withdraw your consent will not affect the processing
              of Personal Information based on your previous consent prior to
              the withdrawal.
            </p>

            <p>
              <strong>1.5</strong> Please take a moment to familiarize yourself
              with our Privacy Policy. If you do not agree with any provisions
              of the Terms or this Privacy Policy, we advise you to not use or
              access the “Website”
            </p>

            <h4>PART A: TERMS FOR USAGE OF THE WEBSITE</h4>
            <h4>GENERAL</h4>
            <p>
              <strong>1.1</strong> Your access, or otherwise any usage of the
              Website means you agree to these Terms read with all the policies
              that may be available on the Website (collectively, the
              “Agreement”).
            </p>
            <h4>2. Type of Information Collected</h4>
            <p>
              <strong>2.1</strong> You may be asked to provide your information
              anytime you visit, access, use or browse the “Website”. We may
              share information and use it consistent with the provisions of
              this Privacy Policy. We may also combine it with other information
              to provide and improve our Products, services, content and
              advertising.
            </p>
            <p>
              <strong>2.2</strong> You agree to provide information, which shall
              be true, correct, up to date and accurate. You may access, amend,
              alter or require deletion of your information partially or fully
              by contacting us at <a href="mailto:cs@avijo.in">cs@avijo.in</a>{" "}
              or call 07067635032.
            </p>
            <p>
              <strong>2.3</strong> The Website is not directed towards minors.
              We do not knowingly collect Personal Information from any User who
              is under 18 (Eighteen) years. If we are made aware that we have
              collected the Personal Information of a person below the age of 18
              (Eighteen) years, we will take steps to delete the information
              within a reasonable time. If a parent or guardian becomes aware
              that his or her child has provided his/ her information on the
              Website without their consent, he or she shall contact us for the
              deletion of such information. In the event of a person below the
              age of 18 (Eighteen) uses the services in contravention to the
              Privacy Policy and Terms, we shall not be held liable or
              responsible for any damage or injury suffered by such person.
            </p>
            <p>
              <strong>2.4</strong> We may collect a variety of information from
              you, including your name, password, mailing address, phone number,
              email address and contact preferences, including any other usage
              and interaction details with us or our affiliates. We may collect
              such information which when combined with other pieces of
              information available with us could reasonably allow you to be
              identified (“Personal Information”). Personal Information shall
              include but is not limited to your full name, personal contact
              numbers, residential address, email address, gender or date of
              birth.
            </p>
            <p>
              <strong>2.5</strong> We may also collect, receive, process or
              store certain sensitive personal data or information consisting
              of, but not limited to:
              <br />
              <strong>2.5.1</strong> Password;
              <br />
              <strong>2.5.2</strong> Financial information such as bank account
              or credit card or debit card or other payment instrument details;
              <br />
              <strong>2.5.3</strong> Physical, physiological and mental health
              condition;
              <br />
              <strong>2.5.4</strong> Any detail relating to the above Personal
              Information categories as provided to us for providing service;
              <br />
              <strong>2.5.5</strong> Any of the information received under above
              Personal Information categories by us for processing, stored or
              processed under lawful contract or otherwise;
              <br />
              <strong>2.5.6</strong> Biometric information;
              <br />
              <strong>2.5.7</strong> Any detail relating to the above Personal
              Information categories as provided to us for providing service;
              and Any of the information received under the above Personal
              Information categories by us for processing, storing, under lawful
              contract, or otherwise.
              <br />
              <strong>2.5.8</strong> (Collectively, referred to as “Sensitive
              Personal Data or Information”).
            </p>

            <h4>3. Use of Information Collected</h4>
            <p>
              <strong>3.1</strong> We may collect, use or process your
              information including Personal Information and Sensitive Personal
              Data or Information for the following purposes:
            </p>
            <p>
              <strong>3.2</strong> For creating and giving you access to your
              registered account on the Website.
            </p>
            <p>
              <strong>3.3</strong> To develop, deliver, process and improve our
              Products, services, content in order to personalize and improve
              your experience.
            </p>
            <p>
              <strong>3.4</strong> To inform you about our Products, services,
              offers, updates, upcoming events, including providing you
              information in relation to order confirmations, invoices,
              technical notices, security alerts.
            </p>
            <p>
              <strong>3.5</strong> For internal analytical and research purposes
              such as auditing, data analysis and research to improve Our
              Products, services and customer communications.
            </p>
            <p>
              <strong>3.6</strong> To meet any legal or regulatory requirement
              or comply with a request from any governmental or judicial
              authority.
            </p>
            <p>
              <strong>3.7</strong> To resolve any request, dispute, grievance or
              complaint raised by you in relation to your use of the Website.
            </p>
            <p>
              <strong>3.8</strong> To detect or monitor any fraudulent or
              illegal activity on the Platform.
            </p>
            <h4>4. Disclosure of Information Collected</h4>
            <p>
              <strong>4.1</strong> We may from time to time be required to
              disclose the information collected from you to our trusted
              third-party service providers who assist us in order to facilitate
              the provision of services and purchase of Products on the Website.
              For instance, we may share your information with third-party
              payment gateway providers to process transactions on the Website.
              By using the Website, you consent to any such disclosure of your
              information with third-party service providers. We ensure that
              such third-party service providers are bound by reasonable
              confidentiality obligations and/or use, maintain, and follow
              generally accepted industry and security standards with respect to
              such information.
            </p>
            <p>
              <strong>4.2</strong> We may also disclose your information when
              such disclosure is requisitioned under any law or judicial decree
              or when we, in our sole discretion, deem it necessary in order to
              protect our rights or the rights of other Users, to prevent harm
              to persons or property, to fight fraud and credit risk.
            </p>
            <p>
              <strong>4.3</strong> We may also disclose or transfer your
              information to any third party as a part of reorganization or a
              sale of the assets, division or transfer of a part or whole of us.
              We shall ensure that the third party to which we transfer or sell
              our assets will have appropriate confidentiality and security
              measures, at least as protective as those described in this
              Privacy Policy, to handle your Personal Information. You will have
              the opportunity to opt out of any such transfer if the new
              entity's planned processing of your information differs materially
              from that set forth in this Privacy Policy.
            </p>
            <p>
              <strong>4.4</strong> A third-party payment gateway provider may be
              required to collect certain financial information from you
              including, but not restricted to, your credit/debit card number or
              your bank account details (collectively referred to as “Financial
              Information”). All Financial Information collected from you by
              such third-party payment gateway providers will be used only for
              billing and payment processes. The Financial Information collected
              from you is transacted through secure digital platforms of
              approved payment gateways, which are under encryption, thereby
              complying with reasonably expected technology standards. The
              verification of the Financial Information shall be accomplished
              only by you through a process of authentication in which we shall
              have no role to play and hence, we shall bear no liability in
              relation to the same. We shall neither be liable nor responsible
              for any actions or inactions of the third-party payment gateway
              providers or any breach of conditions, representations, and
              warranties given by them. We shall also not be obligated to
              mediate or resolve any dispute or disagreement between you and
              such third-party payment service providers.
            </p>
            <p>
              <strong>4.5</strong> While we make best efforts to ensure that
              your information including Personal Information, Financial
              Information, and Sensitive Personal Information or Data is duly
              protected by undertaking security measures prescribed under
              applicable laws, you are strongly advised to exercise reasonable
              discretion while providing Personal Information or Financial
              Information while using the services given that the Internet is
              susceptible to security breaches.
            </p>
            <p>
              <strong>4.6</strong> Your information may also be transferred,
              stored, or processed in any country other than the country in
              which you access the Website. For purposes of sharing or
              disclosing data in accordance with the Privacy Policy, we reserve
              the right to transfer your information outside of your country. By
              using the Website, you consent to such transfer of your
              information outside of your country and also within our
              affiliates, subsidiaries, and partners whose personnel and
              subcontractors, provided they have agreed to ensure the same level
              of data protection as prescribed under this Privacy Policy and the
              data protection laws of India. The information may be transferred
              in the event it is necessary for the performance of the services,
              operation of the Website, and provision of Products as agreed upon
              between us and the third party.
            </p>

            <h4>5. Security</h4>
            <p>
              <strong>5.1</strong> The security of your Personal Information is
              important to us. We have implemented security policies, rules, and
              technical measures, as required under applicable law including
              firewalls, transport layer security, and other physical and
              electronic security measures to protect the Personal Information
              that it has under its control from unauthorized access, improper
              use or disclosure, unauthorized modification, and unlawful
              destruction or accidental loss. When you submit your information
              on the Website, your information is protected through our security
              systems. Please note that we use international and
              industry-recognized standards as per applicable laws, rules, and
              regulations.
            </p>
            <p>
              <strong>5.2</strong> Your information is contained within secured
              networks and is only accessible by a limited number of authorized
              persons who have access rights to such systems or otherwise
              require such information for the purposes provided in this Privacy
              Policy. These authorized persons are also under an obligation to
              keep such information confidential.
            </p>
            <p>
              <strong>5.3</strong> Although we make the best possible efforts to
              transmit and store all the information provided by you in a secure
              operating environment that is not open to the public, you
              understand and acknowledge that there is no such thing as complete
              security and we do not guarantee that there will be no unintended
              disclosures of any information and potential security breaches.
              You agree not to hold us responsible for any breach of security or
              for any action of any third parties that receive your Personal
              Information or events that are beyond our reasonable control
              including, acts of government, computer hacking, unauthorized
              access to computer data and storage devices, computer crashes,
              breach of
            </p>
            <h4>6. Cookies Policy</h4>
            <p>
              <strong>6.1</strong> Due to the communications standards on the
              Internet, when you visit, access, or browse the Website, we
              automatically receive the uniform resource locator of the site
              from which you visit, access, or browse the Website, details of
              the sites you visit on leaving the Website, the internet protocol
              (“IP”) address of each User’s computer operating system, type of
              web browser the User is using, email patterns, and the name of the
              User’s internet service provider. This information is used solely
              to analyze overall User trends and to help us improve our
              services. Please note that the link between the User’s IP address
              and the User’s personally identifiable information is not shared
              with third parties without User’s permission or except when
              required by law or to provide or facilitate the User with the
              services. Notwithstanding the above, the User acknowledges that we
              reserve the right to share some of the aggregate findings,
              including the personal information provided by the Users in an
              unidentifiable, aggregate form, and not the specific data with
              advertisers, sponsors, investors, strategic partners, and others
              in order to help grow the business. The amount of information sent
              to us depends on the settings of the web browser used by the User
              to access the Website. The User may refer to the browser used, if
              the User wishes to learn what information is provided to us.
            </p>

            <p>
              <strong>6.2</strong> The Website uses temporary cookies to store
              certain data. We do not store Personal Information in the cookies.
              Information collected by us, by any means whatsoever, that does
              not personally identify the User as an individual (such as
              patterns of utilization described above) is exclusively owned by
              us and may be used by us and third party service providers for
              technical administration of the Website, user administration,
              research, development, and other purposes.
            </p>

            <p>
              <strong>6.3</strong> You understand that you may set or amend your
              web browsers to delete or disable cookies. If you choose to
              disable cookies on your computer or mobile telecommunication
              device, it may impair, degrade or restrict access to certain areas
              of the Website.
            </p>

            <p>
              <strong>6.4</strong> We may allow other companies or entities to
              serve advertisements to you. These companies or entities include
              third party advertisement servers, advertisement agencies, and
              advertisement technology vendors. We may target some
              advertisements to you that fit a certain general profile. We do
              not use Personal Information to target advertisements to you. In
              the course of serving advertisements or optimizing the services to
              its Users, we may allow authorised third parties to place or
              recognize a unique cookie on the User’s browser.
            </p>

            <p>
              <strong>6.5</strong> We may keep records of all communications,
              both voice telephone calls and non-voice digital chats and emails
              received from and made to Users for the purpose of administration
              of services, research and development, training, business
              intelligence, business development, or for User administration. We
              may share such telephone records with third parties when required
              by law or when required to provide or facilitate the User with the
              services.
            </p>

            <p>
              <strong>6.6</strong> You consent to our reproduction/publishing of
              all testimonials and reviews given by you on the Website in
              relation to the services or the Products. You agree that we may
              edit the testimonials and reviews provided by you and
              reproduce/publish such edited or paraphrased versions of the
              testimonials and reviews on the Website. If the User has any
              concerns with the reproduction/publication of any testimonial or
              review provided by you, the User may contact us at cs@avijo.in or
              call 070676-35032.
            </p>
            <p>
              <h4>7.Opt-Out Policy</h4>
            </p>
            <p>
              <strong>7.1</strong> The third party service providers with whom
              we may share information provided by you are not permitted to
              market their own services or send promotional e-mails or engage in
              promotional communication with you. We provide you with the
              opportunity to opt-out of receiving non-essential, promotional, or
              marketing-related communication from itself or its partners.
            </p>
            <p>
              <strong>7.2</strong> If you wish to remove your contact
              information from all our mailing lists and newsletters, you can
              click on the "unsubscribe" link or follow the instructions in each
              e-mail message. Alternatively, you can contact us at cs@avijo.in
              or call 70676-35032. We reserve the right to limit membership
              based on availability of contact information. All Users will be
              notified by email prior to any actions taken.
            </p>
            <p>
              <h4>8.Retention of information</h4>
            </p>
            <p>
              <strong>8.1</strong> We will retain your information and any data
              for the period necessary to fulfill the purposes outlined in this
              Privacy Policy unless a longer retention period is required or
              permitted under the applicable law.
            </p>
            <p>
              <h4>9.Modification</h4>
            </p>
            <p>
              <strong>9.1</strong> We reserve the right to amend this Privacy
              Policy at any time. The Privacy Policy, as and when modified,
              shall be updated on the Website. We encourage you to review this
              Privacy Policy whenever you visit our Website to understand how
              your Personal Information is used.
            </p>
            <p>
              <h4>10.Grievance Redressal</h4>
            </p>
            <p>
              <strong>10.1</strong> In case, you have any questions, grievance
              or complaints about this Privacy Policy, or about the Platform,
              you may contact our grievance officer on the below mentioned
              details:
            </p>
            <p>
              <a href="mailto:grievance@avijo.in">grievance@avijo.in</a>
            </p>
            <p>
              <h4>11.Governing Law</h4>
            </p>
            <p>
              <strong>11.1</strong> The terms of this Privacy Policy shall be
              governed and construed in accordance with the laws of India. Any
              dispute regarding or arising out of this Privacy Policy shall be
              subject to the exclusive jurisdiction of the courts in Indore,
              Madhya Pradesh.
            </p>
            <p>
              <h4>12.Severability</h4>
            </p>
            <p>
              <strong>12.1</strong> Whenever possible, each section of this
              Privacy Policy shall be interpreted in a manner so as to be valid
              under applicable law. However, in the event of any provision is
              held to be prohibited or invalid, such provision shall be
              ineffective only to the extent of such prohibition or invalidity,
              without invalidating the remainder of such provision or other
              remaining provisions of this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
