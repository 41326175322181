import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link } from "react-router-dom";
import ExpertNav from "../components/Navbar/ExpertNav";
import Footer from "../components/Home/footer/Footer";



const AvijoAlpha = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="App">
            <ExpertNav price={true}/>
            <Header />
            <Carousel />
            <SupportData />
            <Footer />
        </div>
    )
}

const Header = () => (
    <header className="hero bg-white">
        <div class="flex flex-col md:flex-row justify-between p-4">
            <div className="flex flex-col p-2 w-full md:w-1/2 h-full">
                <div className="flex-grow">
                    <div className="flex flex-row items-end">
                        <h1 className="text-[48px] text-black" style={{ fontFamily: 'Gilroy-SemiBold' }}>avijo</h1><h6 className="text-[28px] font-gilroy font-semiBold text-[#12CDB7] italic">Alpha</h6>
                    </div>
                    <span className="font-gilroy font-[500] text-xl text-[#535353]">
                        Avijo Alpha is a cutting-edge, AI-powered Software platform designed to revolutionize the way healthcare facilities operate. As a unified, ABDM-enabled software solution, Avijo Alpha brings together the critical components of healthcare management into a single, streamlined system. This platform is specifically built for healthcare facilities like doctors clinics, hospitals, Diagnosis centres and pharmacies, enabling them to deliver exceptional care while optimizing their daily operations.
                    </span>
                </div>
                <button className="bg-[#0097DB] h-10 w-[130px] rounded-lg mt-4">
                    <span className="text-sm">Get Started</span>
                </button>
            </div>
            <div className="p-4 w-full md:w-1/2 items-center md:ml-12">
                <img src='https://s3-alpha-sig.figma.com/img/0cbe/98ab/c2861e595da7ab1cc69f8cbad9188dc0?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=psLJ5ODWqF7ityUqp5olmK2qFEIpGjfa~TF6-w0Um-K8Fa1oPmaE2CwfR4Etlmt-yINXD5NgWdDZZPYxkXF8vbdU6jRF5-D2Ktl6jejyBlBOe7Y0j6~wCssE5aRb-qwENkONt1m60~zd6L7Cbo6krliecc2E7iCxGJDJ4PyEsO~8H1c3auRl0qJmy-hs9yGh15HWTBvB5NtlTmg7XjylVluU4oVYCBbsENUY50rGlEp26~at6c6n0iFhVcHPLGh6AjkEJb2iByNwnFtV7Ep63KrMd7mqTeTG1qpVeni6iOStyOYbqzfsomnWrKO5n9tIFBnLuUj0KdACzXrTR9apTA__' alt="header" className="h-[320px] w-90" />
            </div>
        </div>
    </header>
);

const CarouselItem = ({ active, index, title, content, buttonText, imgSrc, bgColor, setActive }) => (
    <div className={`${active === index ? 'active' : ''} carousel-item flex flex-col items-center`} style={{ backgroundColor: bgColor }}>
        <h2 className="self-center text-center ml-[20%] w-[60%] pt-5 text-4xl">Transforming Healthcare Operations with AI-Powered Solutions</h2>
        <div className="flex flex-col md:flex-row items-center justify-between mt-4 w-[90%] text-center ml-10">
            {['ABDM Enabled', 'AI optimize', 'Integrated Environment', 'Digital presence and operations', 'Comprehensive ecosystem'].map((text, idx) => (
                <div key={idx} onClick={() => setActive(idx)}>
                    <span className={`font-semibold text-[22px] text-center hover:text-white ${active === idx ? 'text-[#0097DB]' : 'text-black'}`}>{text}</span>
                </div>
            ))}
        </div>
        <div className="flex flex-col md:flex-row justify-between p-4 pt-2 items-center">
            <div className="flex flex-col p-2 w-full md:w-[65%] h-full">
                <div className="flex-grow">
                    <h1 className="text-[36px] font-gilroy font-semiBold text-black ml-4">{title}</h1>
                    {content.map((item, idx) => (
                        <div key={idx} className="flex flex-row items-start mt-2">
                            <img src={require('../Assets/image/arrow.png')} className="w-[9px] h-[13px] mr-2 mt-1" alt="arrow" />
                            <span className="text-black text-[20px] font-gilroy font-medium">{item}</span>
                        </div>
                    ))}
                </div>
                <button className="bg-[#0097DB] p-2 w-[220px] rounded-lg mt-4">
                    <span className="text-sm text-white">{buttonText}</span>
                </button>
            </div>
            <div className="p-4 w-full md:w-[500px] h-[350px] flex justify-center">
                <img src={imgSrc} alt="header" className="h-full w-[100%]" />
            </div>
        </div>
    </div>
);

const Carousel = () => {
    const [active, setActive] = useState(0);

    const items = [
        {
            index: 0,
            title: 'Unified Software Platform',
            content: [
                'Seamlessly integrates with other Avijo products and applications.',
                'Supports end-to-end healthcare management, from patient records to billing',
                'Provides a unified dashboard for real-time monitoring of healthcare operations',
                'Ensures compliance with national and international healthcare regulations',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/c125/0385/9ff1628bbdb24ccdf770e7937d584bc7?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MmeLDcH5FWQbL2jrb9DG4n-phqUXzPhrdprNSqYNeMH7c7PBOC7gjoH1wM7GSTuk6dbOX2-gNW7U4~MgNnHUE1I0ZiWI5n1PeOOY~sRH8cvdgFxLOH219059Z-3rSfUXBHE6HQSChbKj5ANxMlmVEG1c5MUsMpN97Gd9~wjaf6WfCa7zcJ5nyCbtB5oUerc6r14kdj1TwwvH-hRti~no1ULtmEk~s8YURfTOAWcG5usTPpv-Aa-M08uxWF8JnzdI--9NV-zH6EVaHo~3A92KCYZkAWI9z6N1y5fQ7x2Bk9KtesQfCRYzj3IabrWbS2upeXnrt8qqLERznfWua-Ramg__',
            bgColor: '#E7F4FD',
        },
        {
            index: 1,
            title: 'AI-Powered Operations',
            content: [
                'Automates routine tasks such as appointment scheduling and patient follow-ups',
                'Provides predictive analytics for patient outcomes and resource management.',
                'Enhances diagnostic accuracy with AI-driven recommendations.',
                'Facilitates personalized patient care by analyzing health data patterns',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/200a/c2f3/cd757e0b848a893c117797fc240523f1?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XOcMnDuNCde3Oet~w4OPYdY8YqPQ5U5nA56redetcY8gzG4EDtUuhTg92Ww65XonErkTXubMmn3UWFEQU~Mg7euq2myofu6L6i45O2CknBQyzeimbpqgCFPPBr3RWx9tjv26Qab2KVTzE0SHzyXIIGF7pncgb8DxmpQ0Uo4GoRWhcMH13yDMe~Vaef-xMqPo~EGqrxb-FX~dS1GBveCbx9o2RM7XH4dr3LasGAWbaEEDCjlFpJGKS6C5hu9fRGUDQzI2hNYlPAfLOM0OU8eQjtrseXglT7duPTnRvmbkhXNQraYu4S1jb2oIGZIQRTc7FKHZ5axWxVctIC9od2SwNw__',
            bgColor: '#FFFBD4',
        }, {
            index: 2,
            title: 'Linkage Features',
            content: [
                'Enables collaboration between different healthcare facilities and professionals.',
                'Simplifies patient referrals and sharing of medical records.',
                'Supports multi-facility management for hospital chains',
                'Enhances coordination in patient care by linking specialists and general practitioners.',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/3b64/58c4/787ebe50888674d886a27dc5faf49a83?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T6a7cSVqewt8MIFGg8L9FMY6KH4MwSJmgjrpReUUX6LHjQ7G9XCKbfBAjEcoCvbX~jhd3c0qctIC59K0Po2fSt8Xat25PaQsKu3OF2bb3DaY9IEr24FWNaXg6WI3J~xGy3vMm1pAx5mMtJvqW-6DuBkt0GIiBV8StHqkaF8bUPXLolLrc9NnOUFw-bxJhOyJCdGuRUtxdIOwQEBuQGO5De9qHLd~gmJWyBTWSSBDxaBiVnEgl-ekwFdg8idWzfCg0wLKvmC7sdjELC0JClCCzv-1S8FIKR1~Velq5d0atx7uycykoiP5MEukXAP7l9CeErGjx5NhHpfbDrZI5DxwuA__',
            bgColor: '#E1FFD3',
        }, {
            index: 3,
            title: 'E-Prescription & Digital Health Records',
            content: [
                'Issue digital prescriptions directly through the platform.',
                'Maintain digital health records that are easily accessible for both doctors and patients.',
                'Simplify follow-ups and treatment plans with digital documentation.',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/2ead/b998/5709459b4fb969d22ec77e944575d582?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CUVry4hDhUWdJ~BqLnCi8mgqs-2OmZqmSnlx5WLKBtrgs9lazftOMwl1MC~fkKWS0Do~ZHbnfYxjuTBkIL0PE-ZFlF3IUolSylpc~qMfOL-JdOwlRTZm8fyE6iNT8I39wkkf~utKl3wT-hWtgCzpK1JYmbp57-bMVc5maulRvBKLD-NRWobXutNyAB3aRtMZLZGg2ZUMO0A8BPLQPAKOMsG7cb749Z1wdAX4e2iw~Jojjm~xCwQxCEqlVdOvYmhFD1IbJPQWHMliTqMxlToy9CNHaCIFw~dww6Gj-ui03fvO27pWsnFRXbpvJdWT-I8OKiMtWFfXSb-Qx61yc2IwXQ__',
            bgColor: '#C3DBE4',
        }, {
            index: 4,
            title: 'Linkage with Avijo Alpha',
            content: [
                'ISeamless integration with Avijo Alpha for enhanced facility management.',
                'Streamline communication and coordination with other healthcare professionals',
                'Utilize the linkage software to expand your professional network.',
            ],
            buttonText: 'Register as HFR for demo',
            imgSrc: 'https://s3-alpha-sig.figma.com/img/d397/5bf3/1494cbd1dd2b39464dd4ce25980ce76c?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PwLgm0lss-Ielgqxe91NATvFBs132Yd4Z0FELG0Fil20po47-BcArvGU2ULTwKUVxI4~Spqk-fQXaqMFEXfg198QlhwlizqUWxzICjkb-2yMkjbt3tY7UIGC5glS4Kgmnkuf4K0ZjBrdjk48QKbmQJpu~9eW~zSNbpNkcvhJWJsgggItzZLOsjSBpeY8-zSjl-uknbvb~jKs-zAEJ8p0ayUv0jW0dos9deATT0svdO0SD4DXaAsLHMGSzYUNunjzkKuLJMtiBnHp32Rof~7eUpJlHXsa~1wUTqwwBZH0rqlDBtCpfezsztX2fZq9OEQbSk5uiVpE4PytBjej-5Gstw__',
            bgColor: '#F3F2E0',
        }
    ];

    const handlePrev = () => {
        setActive(prev => (prev > 0 ? prev - 1 : items.length - 1));
    };

    const handleNext = () => {
        setActive(prev => (prev < items.length - 1 ? prev + 1 : 0));
    };

    return (
        <div id="carouselExample" className="relative">
            <div className="carousel-inner">
                {items.map(item => (
                    <CarouselItem
                        key={item.index}
                        active={active}
                        index={item.index}
                        title={item.title}
                        content={item.content}
                        buttonText={item.buttonText}
                        imgSrc={item.imgSrc}
                        bgColor={item.bgColor}
                        setActive={setActive} // Pass setActive function
                    />
                ))}
            </div>
            <button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2"
                onClick={handlePrev}
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/left.png')} alt="Previous" className="w-8 h-8" />
            </button>
            <button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2"
                onClick={handleNext}
                style={{ backgroundColor: 'transparent' }}
            >
                <img src={require('../Assets/image/right.png')} alt="Next" className="w-8 h-8" />
            </button>
        </div>
    );
};


const SupportData = () => {

    const data = [
        
        {
            id: 1,
            heading: 'Take the Next Step',
            image: 'https://s3-alpha-sig.figma.com/img/9e71/d256/36d79a5dabcc0c6d6ba338d1eabeb654?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DD2swekIbcUmbDA1VXLLxYI0WYQM~G9u7wYSOKVz8Zbyimvq~DYkpvjJYJSMjmH2QKNRgb3vb9CEGDyzYb9-bpIRdEGTYHmf8QLgmLXNMLGO8NZFfEzJQ1IvmwCZsnYMXHxMAsx3ZYkpGKCGrQZF9ziv40YY0bQZ-DJWXGjh5DHsHl-R-5HY0EabV82bYLQPv4wMNAsAiB2sC-xI2PPoG6dqjlBrzhNzbcBKnlUgPq8APJ9ApAookOENBEVGCbXOMEu5Inld8MZ-AbzREpMhimKE4cfQkED0pZkSpwlHPLkJotx4BGaYc4SW~urMcsk7tVES43IQMawcHAVdRYDuMQ__',
            points: [
                {
                    id: 0,
                    text: 'Experience the future of healthcare management with Avijo Alpha.Platform is designed to empower healthcare professionals and facilities by providing the tools needed to operate more efficiently, connect with other professionals, and enhance patient care. Whether you’re a doctor, clinics,pharmacy or hospital etc.. Avijo Alpha is your partner in delivering top-tier healthcare.'
                }
            ]
        },
        {
            id: 2,
            heading: 'Get Started Today',
            image: 'https://s3-alpha-sig.figma.com/img/edc1/73f9/a9f1714007505afacc9b7e1d10888f9b?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=f1JoW3Gi~OHyQ8e4Bfz0LAyLYQp3WjbWIHu-wWmkKksbpmQvMardc3LXHrMAhYQ89cwvmi0ewmPTaiWfB3ve-z8kxNiuKv-55dRBaWCej7YMHVYCS1QU45ZzEZywd8KPV2TCrbEPQNdxxAkMjLVBlLtoRksJrG7EX8NHXOT4w2Nwm3OdupBNAAA0GChhBJujw0Qv1Gu1pFjiVXRK2hH4SRYCdRDW~FyMq7gKlTzeT0eIPngokqso0gLXmMMTKdKHxMS3dXGe7SS~nYDrKDkqN6OLe3TBj9R7JW7zkAUIL1r7TUNI3cF89jYS2HC-6XSr0VF8nFEJrHaqefgI68i9YA__',
            points: [
                {
                    id: 0,
                    text: 'Discover how Avijo Alpha can transform your practice. Contact us to learn more, request a demo, or sign up to start benefiting from our AI-powered unified software solution. Join the Avijo ecosystem and be part of the healthcare revolution.'
                }
            ]
        },
    ]

    return (
        <>
            {data.map((item, index) => (
                index % 2 === 0 ? (index % 2 === 0 &&
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="p-4 pb-0 md:w-1/2 w-full">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2"
                            />
                        </div>
                        <div className="flex flex-col p-2 md:w-1/2 w-full h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold pl-4">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2 pl-4">
                                            <p className="expert-paragraph">{point.text}
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div key={index} className="flex flex-col md:flex-row pb-4 border-1 rounded-[5px] m-4 justify-between items-center">
                        <div className="flex flex-col p-2 md:w-1/2 w-full h-full">
                            <div className="flex-grow">
                                <h3 className="text-[36px] font-gilroy font-semiBold pl-4">{item.heading}</h3>
                                <ul className="list-disc pl-0">
                                    {item.points.map((point, idx) => (
                                        <div key={idx} className="text-[20px] text-[#424242] font-gilroy font-medium mt-2 pl-4">
                                            <p className="expert-paragraph">{point.text}
                                            </p>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="p-4 pb-0 md:w-1/2 w-full md:ml-28">
                            <img
                                src={item.image}
                                className="h-[310px] w-[500px] rounded-2"
                            />
                        </div>
                    </div>
                )
            ))}
        </>
    )
}


export default AvijoAlpha;
