import React from "react";
import { BiLeftArrowCircle } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import FeesAndPaymentsPolicy from "./FeesAndPaymentsPolicy";
import ShippingAndDeliveryPolicy from "./ShippingAndDeliveryPolicy";
import ReturnRefundAndCancellationPolicy from "./Return-Refund-And-Cancellation-Policy";
import EditorialPolicy from "./EditorialPolicy";

const TermsAndConditions = () => {

  const { option } = useParams();

  React.useEffect(() => {
    localStorage.setItem("myPath", window.location.pathname);
  }, []);


  return (
    <>
      <div className="container mt-5">
        <div className="w-[90%] flex flex-row items-center justify-left mb-4">
          <Link to="/">
            <BiLeftArrowCircle className="text-[36px] text-[#0095D9]" />
          </Link>
        </div>
        <div className="row team-row">
          <div className="col-md-4">
            <h4 className="mb-4">OUR POLICIES</h4>
            <ul>
              <li>
                <Link to="/policy/Terms-and-Conditions" className={option === 'Terms-and-Conditions' && "active-side"}>Terms and conditions</Link>
              </li>
              <li>
                <Link to="/policy/Privacy-policy" className={option === 'Privacy-policy' && "active-side"}>Privacy policy</Link>
              </li>
              <li>
                <Link to="/policy/Fees-and-Payments-policy" className={option === 'Fees-and-Payments-policy' && "active-side"}>
                  Fees and Payments policy
                </Link>
              </li>
              <li>
                <Link to="/policy/Shipping-and-Delivery-policy" className={option === 'Shipping-and-Delivery-policy' && "active-side"}>
                  Shipping and Delivery policy
                </Link>
              </li>
              <li>
                <Link to="/policy/Return-Refund-And-Cancellation-Policy" className={option === 'Return-Refund-And-Cancellation-Policy' && "active-side"}>
                  Return, Refund And Cancellation Policy
                </Link>
              </li>
              <li>
                <Link to="/policy/Editorial-policy" className={option === 'Editorial-policy' && "active-side"}>Editorial policy</Link>
              </li>
            </ul>
            {/* <span className="line"></span> */}
          </div>
          <div className="col-md-8">
            {option === "Terms-and-Conditions" && <div className="col-md-8">
              <h4>Terms and Conditions</h4>
              <p>Welcome to Avijo!</p>
              <p>
                The website, <a href="http://www.avijo.in">www.avijo.in</a>, along
                with the corresponding mobile application ("avijo", "Website",
                "Pharmacy", “us” or "we"). This Website is managed and operated by
                avijo, a company incorporated under the laws of India, having its
                registered office at 50 SANJAY COLONY, WARD NO -07, DANDAPURA,
                Vidisha, Vidisha, Vidisha- 464001, Madhya Pradesh (“we”,
                “Company”, “us” or “our”).
              </p>
              <p>
                The terms and conditions as set out herein (“Terms”) constitutes
                an agreement between the Company, and a natural or legal person
                who accesses and/or uses the Website in any manner (“you” or
                “your”).
              </p>
              <p>
                The Website is a platform that facilitates online purchase by
                consumers of pharmaceutical products like prescription drugs, over
                the counter medicines etc., sold by avijo through its licensed
                premises (“Products”).
              </p>
              <p>
                By using the Website, you expressly agree to be bound by the
                Terms. If you do not agree with any of the Terms, please refrain
                from using the Website. Please note that your usage of the
                Website, the Products, offers or promotions in relation to the
                Products and the Services (defined below) as may be provided by us
                or our affiliates or third parties, shall be governed by these
                Terms and such other terms that may be applicable (“Additional
                Terms”). If these Terms are inconsistent with any Additional
                Terms, the Additional Terms will supersede to the extent of the
                Additional Terms are applicable.
              </p>
              <p>
                This document is an electronic record in terms of Information
                Technology Act, 2000 (“IT Act”), the applicable rules thereunder
                and the provisions pertaining to electronic records in various
                statutes as amended by the IT Act. This electronic record is
                generated by a computer system and does not require any physical
                or digital signatures.
              </p>
              <p>
                Any individual who can enter into legally binding contracts as per
                Indian Contract Act, 1872, i.e., individuals who are 18 years of
                age or older, are of sound mind, and are not disqualified from
                entering into contracts by any law, can use and access the
                Website.
              </p>
              <p>
                THESE TERMS ARE DIVIDED INTO 2 (TWO) PARTS. PLEASE READ THE TERMS
                CAREFULLY.
              </p>
              <h4>PART A: TERMS FOR USAGE OF THE WEBSITE</h4>
              <h4>GENERAL</h4>
              <p>
                <strong>1.1</strong> Your access, or otherwise any usage of the
                Website means you agree to these Terms read with all the policies
                that may be available on the Website (collectively, the
                “Agreement”).
              </p>
              <h4>REGISTRATION OF YOUR ACCOUNT</h4>
              <p>
                <strong>2.1</strong> In order to access or use the Website you
                must set up an account by providing information about yourself as
                prompted on the sign-up page of the Website which may include your
                name, gender, mobile number and such other details relevant for
                placing orders through your account. Your login ID and password
                will be created basis the information provided by you, which you
                can use to access your Avijo account at any time. Your credentials
                shared by you at the time of registration and your Avijo login ID
                and password are referred to as “Account Information”.
              </p>
              <p>
                <strong>2.2</strong> You are solely authorized to operate the
                account created by you. Consequently, it is your responsibility to
                maintain the confidentiality of your Account Information. You
                shall remain solely responsible for all the actions by you on your
                account.
              </p>
              <p>
                <strong>2.3</strong> You will: (a) immediately inform us of any
                unauthorized use or security breach of your account, and (b)
                ensure that you log out of your account at the end of each
                session. You may be held responsible for any losses incurred to
                avijo or any other user due to unauthorized use of their account,
                resulting from your failure to keep your Account Information safe,
                secure and confidential. We reserve the right to refuse access to
                the Website, terminate or suspend accounts, remove or edit content
                at any time without providing any notice to you.
              </p>
              <p>
                <strong>2.4</strong> We constantly monitor the user’s account in
                order to avoid fraudulent accounts and transactions. Users with
                more than one account or availing referral vouchers fraudulently
                shall be liable for legal actions under the applicable law and we
                reserve the right to recover the cost of Products, transaction
                fees, or any other related cost including attorney fees, from such
                person using the Website fraudulently. In an event we detect any
                fraudulent or failed transaction, prior to initiation of legal
                action, we reserve the right to suspend or delete such account
                with immediate effect and dishonour all past and pending orders
                without any liability on our part. However, this shall not absolve
                the User from any liability.
              </p>
              <h4>3. COMMUNICATION AND UNSUBSCRIPTION</h4>
              <p>
                <strong>3.1</strong> You understand that once you have shared your
                Account Information and successfully registered on the Website,
                you may receive SMS or email notifications from Avijo relating to
                your registration and transactions on the Website.
              </p>
              <p>
                <strong>3.2</strong> You consent to receive communications,
                notifications and commercial messages regarding any transaction /
                marketing / promotional activities that are related to your
                transaction on the website or the Products and Services that maybe
                available on the Website from time to time. By sharing your
                Account Information and registering on the Website and/ or
                verifying your contact number with us, you explicitly consent to
                receive marketing / promotional communications (through call, SMS,
                email or other digital and electronic means) from us and/or our
                authorized representatives regarding any new services or
                offerings.
              </p>
              <p>
                <strong>3.3</strong> While the Company endeavours to provide these
                notifications and reminders to you promptly, the Company does not
                provide any guarantee and will not be held liable or responsible
                for any failure to send such notifications/reminders to you.
              </p>
              <p>
                <strong>3.4</strong> You can unsubscribe/opt-out from receiving
                marketing/promotional communications, newsletters and other
                notifications from the Company at any time by following the
                instructions set out in such communications.
              </p>
              <h4>USER WARRANTIES AND RESTRICTIONS</h4>
              <p>
                <strong>4.1</strong> You represent and warrant that: (a) your use
                of the Website will not violate any applicable law or regulation;
                (b) all information that is submitted to the Company in connection
                with the Website is true, accurate and lawful; (c) Your use of the
                Website does not breach any applicable Website policies or
                guidelines and will not cause injury to any person or entity. If
                at any time, the information provided by you is found to be false
                or inaccurate the Company will have the right to reject
                registration, cancel any or all orders, and restrict you from
                using the Website and other affiliated services in the future
                without any prior intimation whatsoever. You agree to indemnify
                the Company and its affiliates for all claims brought by a third
                party.
              </p>
              <p>
                <strong>4.2</strong> You will use the Website for a lawful purpose
                only, and will not undertake any activity that is harmful to the
                Company or the Website or its content or otherwise not envisaged
                through the Website. You have a limited license to access and use
                the Website, solely for the purpose of availing the services,
                subject to these Terms.
              </p>
              <p>
                <strong>4.3</strong> The use of the Website by you is restricted
                to personal use only, and you will not use any portion of this
                Website (including the contents on the Platform) or Services for
                any resale or commercial purpose.
              </p>
              <p>
                <strong>4.4</strong> You will not do any of the following:
              </p>
              <ol>
                <li>
                  <strong>4.4.1</strong> Use any engine, software, tool, agent or
                  other mechanism (such as spiders, robots, avatars, worms, time
                  bombs etc.) to navigate or search the Website;
                </li>
                <li>
                  <strong>4.4.2</strong> Make false or malicious statements
                  against the Product, the Website or the Company;
                </li>
                <li>
                  <strong>4.4.3</strong> Post, copy, submit, upload, distribute,
                  or otherwise transmit or make available any software or other
                  computer files that contain a virus or other harmful component,
                  or otherwise disrupt or damage the Website or any connected
                  network, or otherwise interfere with any person or entity's use
                  or enjoyment of the Website;
                </li>
                <li>
                  <strong>4.4.4</strong> Introduce any trojans, viruses, any other
                  malicious software, any bots or scrape the Website for any
                  information;
                </li>
                <li>
                  <strong>4.4.5</strong> Probe, scan, or test the vulnerability of
                  any system, security or authentication measures implemented by
                  us or otherwise tamper or attempt to tamper with our
                  technological design and architecture;
                </li>
                <li>
                  <strong>4.4.6</strong> Hack into or introduce malicious software
                  of any kind onto the Website;
                </li>
                <li>
                  <strong>4.4.7</strong> Engage in any form of antisocial,
                  disruptive, or destructive acts, including “flaming,”
                  “spamming,” “flooding,” “trolling,”, “phishing” and “griefing”
                  as those terms are commonly understood and used on the internet.
                </li>
              </ol>
              <p>
                <strong>4.5</strong> You are prohibited from hosting, displaying,
                uploading, modifying, publishing, transmitting, updating or
                sharing on or through the Website, any information that:
              </p>
              <ol>
                <li>
                  <strong>4.5.1</strong> belongs to another person and to which
                  You do not have any right;
                </li>
                <li>
                  <strong>4.5.2</strong> is harmful, harassing, blasphemous,
                  defamatory, obscene, pornographic, paedophilic, invasive of
                  another’s privacy, hateful, relating to or encouraging money
                  laundering or gambling, or otherwise harmful in any manner
                  whatsoever;
                </li>
                <li>
                  <strong>4.5.3</strong> harms minors in any way;
                </li>
                <li>
                  <strong>4.5.4</strong> infringes any patent, trademark,
                  copyright or other proprietary rights;
                </li>
                <li>
                  <strong>4.5.5</strong> violates any law for the time being in
                  force;
                </li>
                <li>
                  <strong>4.5.6</strong> deceives or misleads the addressee about
                  the origin of such messages or communicates any information
                  which is grossly offensive or menacing in nature;
                </li>
                <li>
                  <strong>4.5.7</strong> impersonates or defames another person;
                  or
                </li>
                <li>
                  <strong>4.5.8</strong> contains software viruses or any other
                  computer code, files or programs designed to interrupt, destroy
                  or limit the functionality of any computer resource.
                </li>
              </ol>
              <h4>ACCESS OUTSIDE THE REPUBLIC OF INDIA</h4>
              <p>
                5.1 The Company makes no representation that the content on the
                Website is appropriate to be used or accessed outside the Republic
                of India. Any user who use or access the Website from outside the
                Republic of India, do so at their own risk and are responsible for
                compliance with the laws of such jurisdiction. These Terms do not
                constitute, nor may these Terms be used for or in connection with
                any promotional activities or solicitation by anyone in any
                jurisdiction in which such promotional activities or solicitation
                are not authorized or to any person to whom it is unlawful to
                promote or solicit.
              </p>
              <h4>DISCLAIMER</h4>
              <h4>PRODUCTS</h4>
              <p>
                6.1 The Company is solely responsible for providing you with an
                e-commerce website to purchase Product or avail third party
                services, if you deem fit.
              </p>
              <p>
                6.2 It is hereby clarified that that the manufacturers themselves
                are responsible for all compliances relating to the manufacturing
                of the Products including any labelling, mandatory declaration
                requirements, etc. under the applicable law. The manufacturers are
                liable to be compliant under the relevant provisions of applicable
                laws, including but not limited to the Legal Metrology Act, Drugs
                and Cosmetics Act, Food Safety and Standards Act, Drugs and Magic
                Remedies Act etc. and the rules made thereunder.
              </p>
              <p>
                6.3 As a means to assist the users in identifying the Products of
                their choice, to the extent permitted by applicable law, we
                provide visual representations on the Website including graphics,
                illustrations, photographs, images, videos, charts, screenshots,
                infographics and other visual aids. While reasonable efforts are
                made to provide accurate visual representation, we disclaim any
                guarantee or warranty of accuracy of such visual representation or
                description of the Products.
              </p>
              <h4>PRODUCTS</h4>
              <p>
                6.4 You understand that the Website may provide third-party
                services to help you locate and make appointments for consultation
                with doctor/registered medical practitioners and/or diagnostics
                service centres. Please note that this service is provided by
                third-party, and the Website does not guarantee nor make any
                representation with respect to the correctness, completeness or
                accuracy of such services. You should seek emergency help or
                follow-up care when required and continue to consult your primary
                health care provider as you may deem fit.
              </p>
              <p>
                6.5 All communication between you and the third party service
                provider is a separate transaction between you and such
                third-parties. Any liability or claim arising out of such third
                party services availed by you shall be between you and such third
                party. The Company disclaims any liability that may arise out of
                your communication, availment of services (including but not
                limited to deficiency, shortcoming, and inaccuracy) with such
                third parties.
              </p>
              <p>
                6.6 The products and the third party services are provided to you
                on an “as is” basis. We do not make any representations or
                warranties on behalf of the products and third party services. The
                company does not covenant or provide any representations and
                warranties in respect of quality, suitability, accuracy,
                reliability, performance, safety, merchantability, fitness for a
                particular purpose/consumption or the content (including product
                or pricing information and/or specifications) on the Website and
                that the services will be made available at all times.
              </p>
              <h4>PRESCRIPTION MEDICATION POLICY:</h4>
              <p>
                7.1 The Website allows you to purchase the Products (prescription
                medicine) against a valid medical prescription issued by a medical
                expert/ doctor to be provided to a registered pharmacist for the
                purpose of dispensing such medicine (“Prescription Drugs”),
                offered for sale on the Website. In order to purchase Prescription
                Drugs from the Website, You are required to upload a scanned copy
                of the valid prescription on the Website or email the same to the
                Company. The order would not be processed by the Company until it
                receives a copy of a valid prescription. The Company will verify
                the prescription forwarded by You and in case we observe any
                discrepancy in the prescription uploaded by You, we will cancel
                the order immediately.
              </p>
              <h4>CONTENT ON THE WEBSITE</h4>
              <p>
                8.1 All information, content, material, text, graphics, images,
                logos, button icons, software code, interface, design and the
                collection, arrangement and assembly of the content on the Website
                are the property of the Company or its affiliates (“Company
                Content”), and are protected under copyright, trademark and other
                applicable laws. Further, all trademarks, services marks, trade
                names and trade secrets in relation to the Website whether or not
                displayed on the Website, are proprietary to the Company.
              </p>
              <p>
                8.2 The Company may display on the Website any third-party logos,
                trade names, trademarks of other brands, as per the license
                granted to the Company by such brands.
              </p>
              <p>
                8.3 No information, content or material from the Website
                (including the Company Content) may be copied, reproduced,
                republished, duplicated, copied, sold, resold, uploaded, posted,
                transmitted, distributed or otherwise exploited in any way
                (including for any commercial purpose) without Company’s express
                written permission. You will not frame or use framing techniques
                to enclose any trademark, logo, or other proprietary information
                (including images, text, page layout, or form) of the Company and
                its affiliates without express written consent. You may not use
                any meta tags or any other “hidden text” utilizing the Company’s
                or its affiliates’ names or trademarks without the express written
                consent of the Company and/or its affiliates, as applicable. Any
                unauthorized use terminates the permissions granted in these
                Terms.
              </p>
              <h4>LICENSE AND USE OF YOUR CONTENT</h4>
              <p>
                9.1 Your use of the Website, the Services, and access to the
                Company Content (as defined below) is subject to a limited,
                revocable and non-exclusive license which is granted to you when
                you register on the Website.
              </p>
              <p>
                {" "}
                9.2 The license granted to you does not include a license for: (a)
                resale of Products or commercial use of the Website or the Company
                Content, (b) any collection and use of product listings,
                description, or prices, (c) any use of the Website, the Services
                and/or of the Company Content other than as contemplated in these
                Terms, (d) any downloading or copying of Account Information, (e)
                any use of data mining, robots, or similar data gathering and
                extraction tools to extract (whether once or many times) any parts
                of the Website, (e) creating and/ or publishing your own database
                that features parts of the Website.
              </p>
              <p>
                {" "}
                9.3 You grant to the Company a royalty-free, perpetual,
                irrevocable, non-exclusive right and license to adopt, publish,
                reproduce, disseminate, transmit, distribute, copy, use, create
                derivative works from, display worldwide, or act on any material
                posted by you on the Website without additional approval or
                consideration in any form, media, or technology now known or later
                developed, for the full term of any rights that may exist in such
                content, and you waive any claim over all feedback, comments,
                ideas or suggestions or any other content provided through or on
                the Website. You agree to perform all further acts necessary to
                perfect any of the above rights granted by you to the Company,
                including the execution of deeds and documents, at its request.
              </p>
              <h4>INDEMNIFICATION AND LIMITATION OF LIABILITY</h4>
              <p>
                <strong>10.1</strong> You agree to indemnify, defend and hold us
                harmless our affiliates, officers, directors, employees,
                consultants, licensors, agents, and representatives from and
                against any and all third-party claims, losses, liabilities,
                damages, and/or costs (including reasonable attorney fees and
                costs) arising from your access to or use of the Website,
                violation of these Terms, or infringement of any of our or any
                third-party intellectual property or other rights. We will notify
                you promptly of any such claim, loss, liability, or demand, and in
                addition to the foregoing, you agree to provide us with reasonable
                assistance, at your expense, in defending any such claim, loss,
                liability, damage, or cost.
              </p>

              <h4>PART B – TERMS OF SALE ON THE WEBSITE</h4>

              <h4>OUR CONTRACT OF SALE</h4>
              <p>
                <strong>11.1</strong> Listing and display of a Product by us on
                the Website is our invitation to you to make an offer for the
                purchase of such Product. Likewise, the placement of an order on
                the Website by you is your offer to buy the Product(s) from us.
              </p>
              <p>
                <strong>11.2</strong> Once you have placed an order with us for
                the purchase of a Product, you will receive an e-mail and/or
                mobile confirming receipt of your order and containing the details
                of your order (the “Order Confirmation”). The Order Confirmation
                is merely an acknowledgment that we have received your order and
                does not signify our acceptance of your offer.
              </p>
              <p>
                <strong>11.3</strong> We only accept your offer and conclude the
                contract of sale for Products ordered by you when the Product is
                shipped or dispatched to you and an e-mail confirmation and/or
                confirmation to your registered mobile number is sent stating that
                the Product has been shipped or dispatched (the “Shipment
                Confirmation”). We reserve the right, at our sole discretion, to
                refuse or cancel any order for any reason whatsoever before
                sending Shipment Confirmation to you.
              </p>
              <p>
                <strong>11.4</strong> If your order is dispatched in more than one
                package, you may receive separate Shipment Confirmations for each
                package, and each Shipment Confirmation and corresponding dispatch
                will conclude a separate contract of sale between you and us for
                the Product(s) specified in that Shipment Confirmation.
              </p>
              <p>
                <strong>11.5</strong> Any communication from us shall be sent only
                to your registered mobile number and/or email address or such
                other contact number or email address that you may designate, for
                any particular transaction. You shall be solely responsible to
                update your registered mobile number and/or email address on the
                Website in the event there is a change. Further, we may also send
                you notifications and reminders with respect to scheduled
                deliveries of the purchased Products. While we shall make every
                endeavor to share prompt reminders and notifications relating to
                the delivery of purchased Product(s) with you, we shall not be
                held liable for any failure to send such notifications or
                reminders to you.
              </p>
              <p>
                <strong>11.6</strong> Please note that we dispense Products only
                in quantities which correspond to the requirement as per the
                prescription.
              </p>
              <p>
                <strong>11.7</strong> All orders placed on the Website are subject
                to the availability of such Products, our acceptance of your offer
                as per the above, and your continued adherence to these Terms.
              </p>
              <p>
                <strong>11.8</strong> You hereby authorize us to declare and
                provide declarations to any governmental authority on request on
                your behalf, including that the Products ordered by you are for
                personal, non-commercial use.
              </p>

              <h4>RETURN AND REFUND</h4>
              <p>
                <strong>12.1</strong> Please review the Cancellation, Returns, and
                Refund Policy available on the Website, which applies to Products
                availed from us.
              </p>

              <h4>PRODUCT AVAILABILITY</h4>
              <p>
                <strong>13.1</strong> We list availability information for the
                Products on the relevant webpage of the Website. Beyond what we
                say on that webpage or otherwise on the Website, we cannot be more
                specific about availability. Please note that the dispatch
                estimate is not guaranteed and should not be relied upon as such.
                As we process your order, you will be informed by e-mail and/or
                mobile SMS if any Products you order turn out to be unavailable or
                out of stock.
              </p>

              <h4>PRODUCT PRICING</h4>
              <p>
                <strong>14.1</strong> All prices are listed in Indian Rupees.
                Price, as displayed, is inclusive of all applicable taxes.
              </p>
              <p>
                <strong>14.2</strong> Products in your shopping cart on the
                Website will reflect the most recent price as displayed on the
                Product’s information webpage on the Website. Please note that
                this price may differ from the price shown for the Product when
                you first placed it in your shopping cart. Placing a Product in
                your shopping cart does not reserve the price shown at that time.
                It is also possible that a Product’s price may vary between the
                time you place it in your shopping cart and the time you place the
                order. The price as applicable at the time of placing an order
                shall be final.
              </p>

              <h4>LICENSES</h4>
              <p>
                <strong>15.1</strong> You acknowledge that there may be
                licenses/permissions required under the applicable laws to use,
                purchase, or otherwise possess certain Product(s) by you. You will
                be solely responsible for obtaining such licenses/permissions and
                complying with the terms of such licenses/permissions.
              </p>

              <h4>LIMITATION OF LIABILITY</h4>
              <p>
                <strong>16.1</strong> In no event, our aggregate liability shall
                extend beyond the money charged from you for purchases made
                pursuant to an order under which such liability has arisen and
                been established. We will not be responsible for any business loss
                (including loss of profits, revenue, contracts, anticipated
                savings, data, goodwill, or wasted expenditure) or any other
                indirect or consequential loss that is not reasonably foreseeable
                to both you and us when a contract for the sale of Product by us
                to you was formed.
              </p>

              <h4>MISCELLANEOUS</h4>
              <p>
                <strong>17.1 FORCE MAJEURE</strong>
              </p>
              <p>
                We will not be held responsible for any delay or failure to comply
                with its obligations if the delay or failure arises from any cause
                which is beyond our reasonable control.
              </p>
              <p>
                <strong>17.2 WAIVER</strong>
              </p>
              <p>
                No provision in these Terms will be deemed waived, and no breach
                excused unless such waiver or consent is in writing and signed by
                us. Any consent by us to, or waiver of your breach, whether
                expressed or implied, will not constitute consent to, waiver of,
                or excuse for any other different or subsequent breach.
              </p>
              <p>
                <strong>17.3 SEVERABILITY</strong>
              </p>
              <p>
                If any provision of these Terms is held by a court of competent
                jurisdiction to be unenforceable under applicable law, then such
                provision will be excluded from these Terms, and the remainder of
                these Terms will be interpreted as if such provision were so
                excluded and will be enforceable in accordance with its terms;
                provided that, in such event, these Terms will be interpreted so
                as to give effect, to the
              </p>
              <p>
                <strong>17.4 AMENDMENT</strong>
              </p>
              <p>
                <strong>
                  These Terms are subject to amendments and modifications and may
                  be updated from time to time, without any advance notice. You
                  are requested to regularly review the Terms as available on the
                  Website. Your relationship with the Website will be governed by
                  the most current version of these Terms, as published on the
                  Website.
                </strong>
              </p>

              <p>
                <strong>17.5 OTHERS:</strong>
              </p>
              <p>
                <strong>17.5.1</strong> In addition to these Terms, you will also
                ensure that you are in compliance with the Terms of the third
                parties, such as bank offers Terms, brand promotional offers,
                whose links, if any, are contained/embedded in the Services. You
                agree that we will not be liable for any transaction between
                yourself and any such third parties.
              </p>
              <p>
                <strong>17.5.2</strong> These Terms supersede all previous oral
                and written Terms (if any) communicated to you by us, for the use
                of the Website, and the rights and liabilities with respect to any
                Services provided by us shall be limited to the scope of these
                Terms of Use.
              </p>

              <h4>GOVERNING LAW AND DISPUTE RESOLUTION</h4>
              <p>
                <strong>18.1</strong> All disputes and claims arising out of these
                Terms in relation to any or all transactions on the Website are
                governed by the laws of India and shall be subject to the
                exclusive jurisdiction of the competent courts of Indore.
              </p>

              <h4>Grievance Redressal</h4>
              <p>
                <strong>19.1 Customer Support:</strong>
                <br />
                You may contact the Customer Support on{" "}
                <a href="mailto:cs@avijo.in">cs@avijo.in</a>
                <br />
                Toll-free: <a href="tel:07067635032">07067635032</a>
              </p>
            </div>}
            {option === "Privacy-policy" && <PrivacyPolicy />}
            {option === "Fees-and-Payments-policy" && <FeesAndPaymentsPolicy />}
            {option === "Shipping-and-Delivery-policy" && <ShippingAndDeliveryPolicy />}
            {option === "Return-Refund-And-Cancellation-Policy" && <ReturnRefundAndCancellationPolicy />}
            {option === "Editorial-policy" && <EditorialPolicy />}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
