import React from "react";
import App from "../components/Users/header/Header";

export default function AvijoHome() {

    return (
        <div className="w-full h-full items-center flex flex-col">
            <div className="w-full">
                <App />
            </div>
            <div className="flex md:flex-row flex-col items-center w-full justify-center">
                <div className="flex flex-row items-center justify-center md:w-[50%] w-full">
                    <div className="flex flex-col items-center justify-center w-[70%] ">
                        <h1 className="w-[90%] text-black text-[30px] font-bold pt-[20%]">India's first Instant home healthcare partner
                        </h1>
                        <div className="w-[70%] mt-[5%]">
                            <div className="p-[12px] border w-[150px] rounded-full items-center flex flex-col bg-[#0097DB]">
                                <span className="text-white text-md text-[Gilroy-SemiBold]">Download</span>
                            </div>
                        </div>
                    </div>
                    <img src={require('../Assets/image/man.png')} className="w-[300px] h-[400px]"/>
                </div>
                <div className="flex flex-col items-center justify-center md:w-[50%] w-full">
                    <h2 className=" text-black text-[24px] font-[Gilroy-SemiBold] pt-[10%] md:w-[50%]">Scan or Click QR Code to</h2>
                    <h3 className="text-black text-[20px] font-[Gilroy-SemiBold] md:w-[50%]">consult a doctor in easy way</h3>
                    <img src={require('../Assets/image/Frame 2610052.png')} className="h-[290px] w-[280px] ml-2 mt-4" />
                    <h4 className="text-black text-[16px] font-[Gilroy-SemiBold] w-[60%] pt-2">Get the link to Download the App</h4>
                    <div className="flex flex-row items-center w-[60%] justify-between">
                        <input
                            type="text"
                            className="w-[80%] mb-2 h-[40px] border mt-2"
                            placeholder="Enter Your Mobile"
                        // value={phone}
                        // onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="h-[40px] border w-[100px] ml-2 rounded-sm items-center justify-center flex flex-col bg-[#0097DB]">
                            <span className="text-white text-sm text-[Gilroy-SemiBold]">Send SMS</span>
                        </div>
                    </div>
                    <h3 className="text-gray-600 text-[20px] font-[Gilroy-SemiBold] w-[60%] pt-2">Download the App</h3>
                    <div className="w-[60%] self-center flex flex-row items-center justify-between">
                        <img src={require('../Assets/image/googlePlay.png')} className="w-44 h-22" />
                        <img src={require('../Assets/image/appStore.png')} className="w-44 h-22" />
                    </div>
                </div>
            </div>
            {/* <div className="w-[80%] rounded-md bg-[#EDF4F6] h-[130px]"></div> */}
        </div>
    )
}