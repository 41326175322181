import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import NotFound from "./Pages/NotFound";
import Login from "./Pages/Users/Login";
import UserHeader from "./components/Users/header/Header";
import "./styles/index.css";
import CreateAccount from "./Pages/Users/CreateAccount";
import HomeNavbar from "./components/Users/navbar/HomeNav";
import Home from "./Pages/Users/Home";
import UserLoginOtp from "./components/Users/Ui/UserLoginOtp";
import MainHome from "./Pages/Home";
import HomeNav from "./components/Users/navbar/HomeNav";
import TermsAndConditions from "./Pages/Legals/TermsAndConditions";
import PrivacyPolicy from "./Pages/Legals/PrivacyPolicy";
import FeesAndPaymentsPolicy from "./Pages/Legals/FeesAndPaymentsPolicy";
import ShippingAndDeliveryPolicy from "./Pages/Legals/ShippingAndDeliveryPolicy";
import ReturnRefundAndCancellationPolicy from "./Pages/Legals/Return-Refund-And-Cancellation-Policy";
import EditorialPolicy from "./Pages/Legals/EditorialPolicy";
import Verfied from "./Pages/VerfiedPage/Verfied";
import Contact from "./Pages/Introduction/Contact";
import About from "./Pages/Introduction/About";
import ABDM from "./Pages/Introduction/ABDM";
import Safety from "./Pages/Introduction/Safety";
import ContactNav from "./components/Navbar/ContactNav";
import AvijoAlpha from "./Pages/AvijoAlpha";
import AvijoExpert from "./Pages/AvijoExpert";
import DoCare from "./Pages/Introduction/DoCare";
import HealthAssistant from "./Pages/Introduction/HealthAssistant";
import DownloadApp from "./Pages/Introduction/DownloadApp";
import ChooseAcc from "./Pages/Introduction/ChooseAcc";
import GenerateId from "./Pages/GenerateId";
import AbdmOtp from "./Pages/AbdmOtp";
import HfrRegister from "./Pages/HfrRegister";
import Blogs from "./Pages/Blogs";
import Blog from "./components/Blog";
import AvijoHome from "./Pages/AvijoHome";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("myPath", location.pathname);
  }, [location.pathname]);

  const currentPath = location.pathname;


  const pathsToHideNavbar = [
    "/user/login",
    "/user/otp-verify",
    "/user/create-account",
    "/lab/login",
    "/lab/otp-verify",
    "/Privacy-policy",
    "/policy/Shipping-and-Delivery-policy",
    "/policy/Editorial-Policy",
    "/Terms-and-Conditions",
    "/policy/Return-Refund-And-Cancellation-Policy",
    "/policy/Fees-and-Payments-policy",
    "/Verfied",
    "/contact",
    "/",
  ];

  const isNavbarVisible = () => {
    for (let path of pathsToHideNavbar) {
      if (currentPath.startsWith(path)) {
        return false;
      }
    }
    return true;
  };

  const showContactNavbar = currentPath === "/about" || currentPath === "/ABDM";
  const showSafetyNavbar = currentPath === "/safety";
  const showUserHome = currentPath === "/user/home";
  const showHeader = currentPath.startsWith("/user") && !showUserHome;

  const showNavbar = isNavbarVisible();

  return (
    <>
      {/* {showHPPProfileNavbar && <HPPProfileNavbar />} */}
      {showContactNavbar && <ContactNav />}
      {showSafetyNavbar && <ContactNav />}
      {/* {showProfileNavbar && <ProfileNavbar />} */}
      {showHeader && <UserHeader />}
      {showUserHome && <HomeNav />}
      {showNavbar && <HomeNavbar />}
      {/* {showProfileEditNavbar && <ProfileEditNav />} */}
      {/* {showHPPProfileEditNavbar && <HPPProfileEditNav />} */}
      {/* {showPharmacyProfileEditNavbar && <PharmacyProfileEditNav />} */}
      {/* {showLabProfileEditNavbar && <LabProfileEditNav />} */}
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<AvijoHome />} />
        <Route path="/provider" element={<MainHome />} />
        {/* User Route */}
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/otp-verify" element={<UserLoginOtp />} />
        <Route path="/user/create-account" element={<CreateAccount />} />
        {/* Doctor Route */}

        {/* legals */}
        <Route path="/policy/:option" element={<TermsAndConditions />} />
        {/* Verifed */}
        <Route path="/Verfied" element={<Verfied />} />
        <Route path="/safety" element={<Safety />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/ABDM" element={<ABDM />} />
        <Route path="/Avijo/Alpha" element={<AvijoAlpha />} />
        <Route path="/Avijo/Expert" element={<AvijoExpert />} />
        <Route path="/Docare" element={<DoCare />} />
        <Route path="/HealthAssistant" element={<HealthAssistant />} />
        <Route path="/DownloadApp" element={<DownloadApp />} />
        <Route path="/ChooseAccount" element={<ChooseAcc />} />
        <Route path="*" element={<NotFound />} />
        {/*Abha Route */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>
    </>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
