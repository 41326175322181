import React from "react";
import Footer from "../../components/Home/footer/Footer";
import { Form, Button } from "react-bootstrap";

const Contact = () => {
  return (
    <>
      <div className="contact border-b-2 mb-5">
        <div className="contact-second">
          <h1>Contact Us</h1>
          <p>
            If you have questions about our products, support services, or
            anything else, let us know and we'll respond promptly.
          </p>
        </div>
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-4">
              <div className="card contact-form">
                <div className="card-body">
                  <h5 className="card-title">Contact Us</h5>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="interestedIn" className="form-label">
                        Interested In
                      </label>
                      <select className="form-select" id="interestedIn">
                        <option>
                          Support for creating any HSPP profile(for Healthcare
                          Providers[HSPP])
                        </option>
                        <option>
                          Free Trial/Demo for any HSPP software(for HSPP)
                        </option>
                        <option>Channel Partnership(for HSPP)</option>
                        <option>
                          Advertising on avijo platforms(for HSPP)
                        </option>
                        <option>
                          Career Opportunities(for Jobs Applicants)
                        </option>
                        <option>Others</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Mobile" className="form-label">
                        Mobile
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="Mobile"
                        placeholder="Enter your Mobile"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <select className="form-select" id="country">
                        <option>India</option>
                        <option>United States</option>
                        <option>United Kingdom</option>
                        <option>Australia</option>
                        <option>Canada</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="Enter your city"
                      />
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="3"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                style={{
                  padding: "20px 20px",
                  height: "400px",
                  background: " #ECF3F5",
                }}
              >
                {" "}
                <h5 className="mb-4">Our branches</h5>
                <form>
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <select
                      className="form-select w-50"
                      id="city"
                      // value={selectedCity}
                      // onChange={handleCityChange}
                    >
                      <option value="indore ">Indore </option>
                    </select>
                  </div>
                </form>
                <div className="card mt-4 w-75 border-0">
                  <div className="card-body">
                    <h6 className="card-title">Indore </h6>
                    <p className="card-text">
                      3rd Floor, Headquarter Building, Satya Sai Square, Indore
                    </p>
                    <a
                      href="https://www.google.com/maps"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="card-link"
                    >
                      Get Directions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
