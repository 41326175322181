import React from "react";
import "./HealthPharmacy.css";
import { Link } from "react-router-dom";
function HealthPharmacy() {
  return (
    <>
      <section class="text-gray-600 body-font relative">
        <div className="container py-24 mx-auto">
          <div className="row">
            {" "}
            <div className="col-md-6">
              <iframe
                width="560"
                height="450"
                src="https://www.youtube.com/embed/ICX5Hw5shNw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-md-6">
              <div className="health-left-sec ">
                <h3 className="heading text-start text-black">Avijo HPP</h3>
                {/* <h3 className="left-heading"> User App </h3> */}
                <p className="userApp-para">
                  Avijo HPP is a web application developed for Health Product
                  Provider (HPP) companies, including pharmaceutical firms,
                  medical device manufacturers, and suppliers. This platform
                  allows HPP companies to manage their product listings, track
                  inventory, process orders, and interact with healthcare
                  facilities and professionals. Avijo HPP aims to streamline the
                  supply chain in the healthcare industry, ensuring timely
                  delivery of essential medical products and fostering a more
                  connected and responsive healthcare ecosystem.
                </p>

                <div className="health-para">
                  <p> ✔ Create Company Account.</p>
                  <p> ✔ Add/Edit catalog.</p>
                  <p> ✔ Live order tracking in B2B trade.</p>
                  <p> ✔ Advertising on platform for B2B & B2C.</p>
                </div>
                <Link
                  className="health-btn text-decoration-none pt-3 ps-3"
                >
                  Learn More / Register as HPP Company
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HealthPharmacy;
